var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(_vm.header)?_c('PanelHeaderEquipmentList',{attrs:{"title":_vm.title,"nav":_vm.nav,"connector_id":_vm.connectorId,"icon":"fa fa-dashboard"}}):_vm._e(),(_vm.isEmpty)?_c('div',{staticClass:"tab-pane active",attrs:{"role":"tabpanel","aria-labelledby":""}},[_c('EmptyListAlert',{attrs:{"buttonLabel":"add_new_data","importLabel":"import_data","hideImport":!_vm.$can('manage', 'DadoCadastro') || _vm.hideImport(),"isInstance":_vm.connector && _vm.connector.base_model_id != null,"newItemPath":_vm.newItemPath,"disabled":_vm.isModelBased},on:{"import":function($event){return _vm.importData()}}})],1):[(_vm.connector)?_c('div',{staticClass:"tab-pane active",attrs:{"id":"edit","role":"tabpanel","aria-labelledby":""}},[(_vm.list)?_c('SearchableTable',{ref:"stbl",staticClass:"searchable-table",attrs:{"tableClass":_vm.tableClass,"items":_vm.list,"fields":_vm.fields,"commands":_vm.commands,"multiColumnOrder":false,"clientSort":true,"pagination":_vm.pagination,"maxResult":_vm.maxResult,"deepSearch":false,"multiSelection":_vm.multiSelection,"disabled":_vm.cloneEnabled,"sortDef":{
          column: _vm.cloneEnabled || !_vm.editable ? 'name' : 'draft_name',
          asc: true
        }},on:{"select":_vm.onSelect,"command":_vm.onCommand,"loadNextPage":_vm.onLoadNextPage,"multiColumnSort":_vm.onMultiColumnSort,"nItems":function($event){_vm.maxResult = $event}},scopedSlots:_vm._u([{key:"label",fn:function(entry){return [_c('MQTTTopicSpan',{attrs:{"entry":entry.item,"active":_vm.activeTopic == entry.item.id},on:{"active":function($event){_vm.activeTopic = entry.item.id}}})]}},{key:"extraButtons",fn:function(){return [_c('span',{staticClass:"btn btn-default",attrs:{"id":"export","disabled":_vm.list && _vm.list.length == 0,"title":_vm.$t('hints.button_export')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.downloadCSV(_vm.connector)}}},[_c('i',{staticClass:"fa fa-file-excel-o"})]),(_vm.multiSelection.key)?_c('span',{staticClass:"btn btn-default",attrs:{"id":"mass_remove","disabled":!_vm.massRemoveEnabled,"title":_vm.$t('mass_remove')},on:{"click":_vm.massRemove}},[_c('i',{staticClass:"fa fa-trash-o"})]):_vm._e()]},proxy:true},{key:"statistics",fn:function({ itemsInPage }){return [_c('ResourceStatistics',{attrs:{"resource":"data","total":_vm.maxResult,"showing":itemsInPage,"icon":"glyphicon glyphicon-stats"}})]}},(!_vm.cloneEnabled && _vm.editable)?{key:"draft_name",fn:function(entry){return [_c('div',{staticClass:"column-content",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"form-group form-group-sm",class:{
                'has-error': _vm.draftError(entry.item, 'name')
              }},[_c('input',{staticClass:"form-control text-left",attrs:{"type":"text","title":entry.item?.identity_embedded_app
                    ? `${_vm.$t('titles.identity_embedded_app')}: ${
                        entry.item?.identity_embedded_app
                      }`
                    : '',"disabled":!_vm.draftEnabled(entry.item, 'name')},domProps:{"value":_vm.draftValue(entry.item, 'name')},on:{"input":function($event){return _vm.draftValue(entry.item, 'name', $event)},"keydown":_vm.onKeyDown,"contextmenu":function($event){$event.preventDefault();$event.stopPropagation();}}})])])]}}:null,(!_vm.cloneEnabled && _vm.editable)?{key:"draft_label",fn:function(entry){return [_c('div',{staticClass:"column-content",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"form-group form-group-sm",class:{
                'has-error': _vm.draftError(entry.item, 'label')
              }},[(entry.item.is_local)?_c('input',{staticClass:"form-control",attrs:{"type":"text","disabled":true},domProps:{"value":_vm.$t('not_available')}}):_c('div',{staticClass:"input-group"},[_c('input',{staticClass:"form-control text-left",attrs:{"type":"text","disabled":!_vm.draftEnabled(entry.item, 'label')},domProps:{"value":_vm.draftValue(entry.item, 'label')},on:{"input":function($event){return _vm.draftValue(entry.item, 'label', $event)}}}),_c('MQTTTopicSpan',{staticClass:"input-group-addon btn",attrs:{"entry":_vm.draftItem(entry.item)}})],1)])])]}}:null,(!_vm.cloneEnabled && _vm.editable)?{key:"draft_source_value",fn:function(entry){return [_c('div',{staticClass:"column-content",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"form-group form-group-sm"},[(entry.item.is_local)?[_c('input',{staticClass:"form-control disabled no-arrows",staticStyle:{"padding-left":"5px"},attrs:{"disabled":"","type":"number"},domProps:{"value":_vm.$t('local_data')}})]:[(_vm.delayedRender)?_c('select',{staticClass:"form-control",attrs:{"disabled":!_vm.draftEnabled(entry.item, 'source_value')},domProps:{"value":_vm.draftValue(entry.item, 'source_value')},on:{"input":function($event){return _vm.draftValue(entry.item, 'source_value', $event)}}},_vm._l((_vm.dataValueSourceTypesFiltered(entry.item)),function(o){return _c('option',{key:o.id,domProps:{"value":o.id}},[_vm._v(" "+_vm._s(o.name)+" ")])}),0):_vm._e()]],2)])]}}:null,(!_vm.cloneEnabled && _vm.editable)?{key:"draft_memory_address",fn:function(entry){return [_c('div',{staticClass:"column-content",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"form-group form-group-sm",class:{
                'has-error': _vm.draftError(entry.item, 'memory_address')
              }},[_c('input',{staticClass:"form-control text-right no-arrows",attrs:{"type":"number","disabled":!_vm.draftEnabled(entry.item, 'memory_address')},domProps:{"value":_vm.draftValue(entry.item, 'memory_address')},on:{"input":function($event){return _vm.draftValue(entry.item, 'memory_address', $event)}}})])])]}}:null,(!_vm.cloneEnabled && _vm.editable)?{key:"draft_local_storage_identity_number",fn:function(entry){return [_c('div',{staticClass:"column-content",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"form-group form-group-sm",class:{
                'has-error': _vm.draftError(
                  entry.item,
                  'local_storage_identity_number'
                )
              },staticStyle:{"padding":"0","margin":"0"}},[_c('input',{staticClass:"form-control text-right",attrs:{"type":"text","list":"scopes","disabled":!_vm.draftEnabled(entry.item, 'local_storage_identity_number')},domProps:{"value":_vm.draftValue(entry.item, 'local_storage_identity_number')},on:{"input":function($event){return _vm.draftValue(
                    entry.item,
                    'local_storage_identity_number',
                    $event
                  )}}})])])]}}:null,{key:"toggle",fn:function(entry){return [_c('ResourceToggle',{attrs:{"item":entry.item}})]}},(!_vm.cloneEnabled && _vm.editable)?{key:"draft_memory_type",fn:function(entry){return [_c('div',{staticClass:"column-content",class:{
              'has-error': _vm.draftError(entry.item, 'memory_type')
            },on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"form-group form-group-sm"},[(_vm.delayedRender)?_c('select',{staticClass:"form-control",attrs:{"disabled":!_vm.draftEnabled(entry.item, 'memory_type')},domProps:{"value":_vm.draftValue(entry.item, 'memory_type')},on:{"input":function($event){return _vm.draftValue(entry.item, 'memory_type', $event)}}},_vm._l((_vm.dataMemoryTypesFiltered(entry.item)),function(o,ix){return _c('option',{key:ix,domProps:{"value":o.id,"selected":o.id == _vm.draftValue(entry.item, 'memory_type')}},[_vm._v(" "+_vm._s(o.name)+" "+_vm._s(entry.item.memory_size > 1 && _vm.draftValue(entry.item, "memory_type") == o.id ? ` [${entry.item.memory_size}]` : "")+" ")])}),0):_vm._e()]),(_vm.draftError(entry.item, 'memory_type'))?_c('div',{staticClass:"error-description",attrs:{"title":`${_vm.$t('current_value')}\n${entry.item.memory_type.id} - ${
                  entry.item.memory_type.name
                }`}},[_c('i',{staticClass:"fa fa-save"}),_vm._v(" "+_vm._s(entry.item.memory_type.name)+" ")]):_vm._e()])]}}:null,{key:"info",fn:function(entry){return [_c('span',{staticStyle:{"white-space":"nowrap"},attrs:{"title":_vm.info(entry.item).title}},[_vm._v(" "+_vm._s(_vm.info(entry.item).value)+" ")])]}}],null,true)}):_vm._e(),(!_vm.cloneEnabled && _vm.editable)?_c('div',{staticClass:"table-form-footer"},[_c('span',{staticClass:"btn btn-default pull-left",attrs:{"disabled":!_vm.draftDirty()},on:{"click":function($event){return _vm.draftReset()}}},[_c('i',{staticClass:"fa fa-undo"}),_vm._v(" "+_vm._s(_vm.$tc("restore", 1))+" ")]),_c('span',{staticClass:"btn btn-primary pull-right",attrs:{"disabled":_vm.isSaving || !_vm.draftDirty() || _vm.draftError()},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('i',{class:_vm.isSaving ? 'fa fa-refresh fa-spin' : 'fa fa-save'}),_vm._v(" "+_vm._s(_vm.$tc(_vm.isSaving ? "saving" : "save", 1))+" ")])]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"tab-pane",attrs:{"id":"list","role":"tabpanel","aria-labelledby":""}})],_c('ModalImportFile',{attrs:{"connectorId":_vm.connector_id,"deviceId":_vm.device_id,"entity":_vm.importEntity},on:{"close":_vm.clearImportEntity,"resource_imported":_vm.resourceImported}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }